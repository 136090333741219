import React from 'react'
import Countdown from '../components/Countdown'
import Carousel from '../components/Carousel'
const images = [
  {
    src: process.env.PUBLIC_URL + '/images/header1.jpeg',
    altText: 'Slide 1'
  },
  {
    src: process.env.PUBLIC_URL + '/images/header2.jpeg',
    altText: 'Slide 2'
  },
  {
    src: process.env.PUBLIC_URL + '/images/header3.jpeg',
    altText: 'Slide 3'
  }
]

const Home = () => {
  return (
    <>
      <Carousel images={images}>
        <Countdown until={new Date('2021-07-24T12:00:00.000Z')} />
      </Carousel>
    </>
  )
}

export default Home
