import React from 'react'
import { array, any } from 'prop-types'

import styles from './Carousel.module.scss'

class Carousel extends React.PureComponent {
  componentDidMount() {
    const Flickity = require('flickity')
    if (!this.carousel) return

    this.carousel = new Flickity(this.carousel, {
      wrapAround: true,
      prevNextButtons: false,
      autoPlay: 5000,
      pageDots: false,
      adaptiveHeight: false,
      draggable: false,
      pauseAutoPlayOnHover: false,
      lazyLoad: true
    })
  }

  render() {
    const { images, children } = this.props
    return (
      <div className={styles.Container}>
        <div
          className={styles.Carousel}
          ref={carousel => {
            this.carousel = carousel
          }}
        >
          {images &&
            images.map((img, i) => (
              <div
                key={`Carousel-image-${i}`}
                className={styles.BackgroundImage}
                style={{ backgroundImage: `url(${img.src})` }}
              />
            ))}
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    )
  }
}

Carousel.propTypes = {
  images: array.isRequired,
  children: any
}

export default Carousel
