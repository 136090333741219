import React, { useState } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'
import { NavLink as Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import bee from '../icons/bee.svg'

const Navigation = () => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const changeLanguage = async lang => {
    i18n.changeLanguage(lang)
    await i18n.changeLanguage(lang)
  }

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand tag={Link} to="/">
          <img src={bee} alt="Bee" height={30} width={30} /> The Jackies
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav
            className={i18n.dir() === 'ltr' ? 'me-auto' : 'pe-0 me-4'}
            navbar
          >
            <NavItem>
              <NavLink tag={Link} to="/wedding" activeClassName="active">
                {t('NavWedding')}
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className={i18n.dir() === 'ltr' ? 'ms-auto' : 'me-auto'} navbar>
            <NavItem onClick={() => changeLanguage('en')}>
              <NavLink active={i18n.language === 'en'}>English</NavLink>
            </NavItem>
            <NavItem onClick={() => changeLanguage('es')}>
              <NavLink active={i18n.language === 'es'}>Español</NavLink>
            </NavItem>
            <NavItem onClick={() => changeLanguage('fr')}>
              <NavLink active={i18n.language === 'fr'}>Français</NavLink>
            </NavItem>
            <NavItem onClick={() => changeLanguage('ar')}>
              <NavLink active={i18n.language === 'ar'}>العربية</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Navigation
