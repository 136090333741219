import React from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Surround.module.scss'

const Surround = ({ children, className, ...other }) => {
  return (
    <div className={classNames(styles.Surround, className)} {...other}>
      {children}
    </div>
  )
}

Surround.propTypes = {
  children: node.isRequired,
  className: string
}

export default Surround
