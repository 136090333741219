import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Col, Row, Table } from 'reactstrap'
import format from 'date-fns/format'
import Surround from '../components/primitive/Surround'

const Home = () => {
  const { t } = useTranslation()
  return (
    <Container className="py-5">
      <Row>
        <Col lg={8} className="mx-auto">
          <Surround className="text-center mb-5">
            <p className="h1 mb-0 font-weight-bold text-uppercase">
              {t('WeddingScheduleTitle')}
            </p>
            <p className="h5 mb-0">{t('WeddingScheduleSubtitle')}</p>
          </Surround>
        </Col>
      </Row>
      <Row>
        <Col lg={8} className="mx-auto">
          <Surround className="text-center mb-5">
            <p className="h1 mb-0 font-weight-bold text-uppercase">
              <h2>{t('WeddingScheduleTableHeader')}</h2>
            </p>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t('WeddingScheduleHeaderTime')}</th>
                  <th>{t('WeddingScheduleHeaderActivity')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {format(new Date('2021-07-24T08:00:00.000Z'), 'h:mm a')}
                  </td>
                  <td colSpan="2">{t('WeddingScheduleRowOneText')}</td>
                </tr>
                <tr>
                  <td>
                    {format(new Date('2021-07-24T12:00:00.000Z'), 'h:mm a')}
                  </td>
                  <td colSpan="2">{t('WeddingScheduleRowTwoText')}</td>
                </tr>
                <tr>
                  <td>
                    {format(new Date('2021-07-24T14:00:00.000Z'), 'h:mm a')}
                  </td>
                  <td colSpan="2">
                    <a
                      href="https://goo.gl/maps/w1Dhd8c7ouLMaUZe9"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {t('WeddingScheduleRowThreeText')}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    {format(new Date('2021-07-24T16:00:00.000Z'), 'h:mm a')}
                  </td>
                  <td colSpan="2">
                    <a
                      href="https://g.page/WillieWastlesRestaurant?share"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {t('WeddingScheduleRowFourText')}
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Surround>
        </Col>
      </Row>
      <Row>
        <Col lg={8} className="mx-auto">
          <Surround className="text-center mb-5">
            <p className="h1 mb-0 font-weight-bold text-uppercase">
              {t('WeddingScheduleNextYearTitle')}
            </p>
            <p className="h5 mb-0">{t('WeddingScheduleNextYearText')}</p>
          </Surround>
        </Col>
      </Row>
    </Container>
  )
}

export default Home
