import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { instanceOf } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Surround from './primitive/Surround'

import styles from './Countdown.module.scss'

const Countdown = ({ until }) => {
  const { t } = useTranslation()

  const calculateDeltas = () => {
    if (Date.now() > until) {
      return {}
    }
    let delta = Math.abs(until - Date.now()) / 1000

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400)
    delta -= days * 86400

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60

    // what's left is seconds
    const seconds = Math.floor(delta % 60)
    return { days, hours, minutes, seconds }
  }

  const [times, setTime] = useState(calculateDeltas())

  useEffect(() => {
    const tick = () => {
      setTime(calculateDeltas())
    }
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return (
    <Surround className={classnames(styles.Container, 'text-center')}>
      <h1 className="font-weight-bold text-uppercase">{t('CountdownTitle')}</h1>
      <div className={classnames(styles.Countdown, 'py-4')}>
        {Object.keys(times).length === 0 ? (
          <h1 className="font-weight-bold">{t('CountdownFinished')}</h1>
        ) : (
          <>
            <div className={styles.CountdownGroup}>
              <span className="h1 font-weight-bold">{times.days}</span>{' '}
              {t('CountdownDays')}
            </div>
            <div className={styles.CountdownGroup}>
              <span className="h1 font-weight-bold">{times.hours}</span>{' '}
              {t('CountdownHours')}
            </div>
            <div className={styles.CountdownGroup}>
              <span className="h1 font-weight-bold">{times.minutes}</span>{' '}
              {t('CountdownMinutes')}
            </div>
            <div className={styles.CountdownGroup}>
              <span className="h1 font-weight-bold">{times.seconds}</span>{' '}
              {t('CountdownSeconds')}
            </div>
            <div className="mt-5">
              {t('CountdownInYourTimezone', { time: until.toLocaleString() })}
            </div>
          </>
        )}
      </div>
    </Surround>
  )
}

Countdown.propTypes = {
  until: instanceOf(Date)
}

export default Countdown
