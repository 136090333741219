import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Navigation from './components/Navigation'
import Home from './pages/Home'
import Wedding from './pages/Wedding'

const App = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    document.querySelector('html').lang = i18n.language
    document.querySelector('body').dir = i18n.dir()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <Router>
      <Navigation />

      <Switch>
        <Route path="/wedding">
          <Wedding />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <footer className="Footer">
        Website by <a href="https://jackburgess.dev">Jack Burgess</a> Icons made
        by{' '}
        <a
          href="https://www.freepik.com"
          title="Freepik"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Freepik
        </a>{' '}
        from{' '}
        <a
          href="https://www.flaticon.com/"
          title="Flaticon"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          flaticon.com
        </a>{' '}
        I made this really quickly sozz
      </footer>
    </Router>
  )
}

export default App
