import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import ar from './locales/ar/translation.json'
import fr from './locales/fr/translation.json'

const isDevelopment = [undefined, 'development'].includes(process.env.NODE_ENV)
i18n
  .use(LanguageDetector)
  // connect with React
  .use(initReactI18next)

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common'],
    defaultNS: 'common',
    resources: {
      en: { common: en },
      es: { common: es },
      ar: { common: ar },
      fr: { common: fr }
    },
    nonExplicitSupportedLngs: true,
    debug: isDevelopment,
    // lng: 'en',
    fallbackLng: isDevelopment ? 'dev' : 'en',
    whitelist: [isDevelopment && 'dev', 'en', 'es', 'ar', 'fr'].filter(Boolean),
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie']
    }
  })

export default i18n
